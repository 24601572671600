/*
	Name:				Core.js
	Description:        Core script for the theme.
	Version:            1.0.0
	Author:             Garrison Hughes
*/
var mobileMenuIcon = function() {
    var screenWidth = document.body.clientWidth;
    if (screenWidth < 865) {
        $('.menu-item-has-children').addClass('disable-hover');
        $('.menu-items-btn').addClass('show');
        $('.menu-items-btn').removeClass('hide');
        $('.menuBtn').removeClass('hide');
        $('.searchIcon').removeClass('activateSearchOverlay');
    } else if (screenWidth >= 865) {
        $('.menu-item-has-children').removeClass('disable-hover');
        $('.menu-items-btn').addClass('hide');
        $('.menu-items-btn').removeClass('show close');
        $('.menuBtn').addClass('hide');

        if ($('.menuBtn').hasClass('close-menu')) {
            $('.menuBtn').removeClass('close-menu');
            $('.menuBtn').addClass('open-menu');
        }

        $('.navMenu').removeClass('show');
        $('.navMenu').removeClass('hide');

        if ($('ul.sub-menu').hasClass('show')) {
            $('ul.sub-menu').removeClass('show');
        }
        $('.searchIcon').addClass('activateSearchOverlay');
    }
};

function expandCollapseMenu(evt) {
    if ($(this).hasClass('open-menu')) {
        $(this).removeClass('open-menu').addClass('close-menu');
        $('.navMenu').removeClass('hide').addClass('show');
    } else {
        $(this).removeClass('close-menu').addClass('open-menu');
        $('.navMenu').removeClass('show').addClass('hide');
    }
}

function expandCollapseMenuItem(evt) {
    var $span = $(evt.currentTarget);
    var $item = $(evt.currentTarget).next('ul.sub-menu');

    if ($span.hasClass('show')) {
        $span.removeClass('show').addClass('close');
        $item.removeClass('hide').addClass('show');
    } else {
        $span.addClass('show').removeClass('close');
        $item.removeClass('show').addClass('hide');
    }
}

function displayDesktopSearch() {
    if ($('.searchIcon').hasClass('activateSearchOverlay')) {
        $('#search-overlay').css('display', 'block');
        $('body').css('overflow', 'hidden');
    }
    return;
}

function registerResourceSectionActive(){
    var classToAdd = "current_page_item";
    if(document.location.href.indexOf("/resources/") > -1){
        $(".main-menu-item:contains('Resources')").addClass(classToAdd);
    }
}

function hideDesktopSearch() {
    $('#search-overlay').css('display', 'none');
    $('body').css('overflow', 'visible');
}

$('.menu-items-btn').on('click tap', expandCollapseMenuItem);
$('#mobileMenuButton').on('click tap', expandCollapseMenu);
$('.searchIcon button').on('click tap', displayDesktopSearch);
$('.search-text i').on('click tap', hideDesktopSearch);

// Add scrolling for anchor links
$('a[href*=\\#]:not([href=\\#])').click(function() {
    if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
        location.hostname == this.hostname
    ) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
            $('html,body').animate({
                    scrollTop: target.offset().top
                },
                1000
            );
            return false;
        }
    }
});

/* Our Partners Add active class to btn */

var $bgLogo = $('#bgLogo');

function buttonPartners() {
    var screenWidth = document.body.clientWidth;
    if (screenWidth >= 626) {
        $bgLogo.fadeIn();
        $('.lg-logo-container').addClass('hide');
        $('div.partner-block').on('mouseenter mouseleave', function() {
            $(this).siblings().removeClass('active');
            $(this).addClass('active');
            var $displayLogo = $('.active img.lg-logo').attr('src');
            $bgLogo.attr('src', $displayLogo);
            $bgLogo.toggleClass('show');
        });
    } else if (screenWidth < 625) {
        $bgLogo.fadeOut();
        $('.lg-logo-container').removeClass('hide');
        $('div.partner-block').on('mouseenter mouseleave', function() {
            $(this).siblings().removeClass('active');
            $(this).addClass('active');
            $('.active img.lg-logo').toggleClass('show');
        });
    }
}

//*** Slick Stuff */

if ($('.masthead-image-slider').length > 0) {
    $('.masthead-image-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 10000,
        speed: 1000,
        fade: true,
        cssEase: 'ease',
        draggable: false,
        // dots: false,
        arrows: false,
        asNavFor: '.masthead-content-slider'
    });
    $('.masthead-content-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 10000,
        speed: 1000,
        fade: true,
        cssEase: 'ease',
        draggable: false,
        dots: true,
        arrows: false,
        asNavFor: '.masthead-image-slider',
        appendDots: $('.slick-dots-masthead')
    });
}

$('.prev').click(function() {
    $('.masthead-content-slider').slick('slickPrev');
    $('.masthead-image-slider').slick('slickPrev');
});

$('.next').click(function() {
    $('.masthead-content-slider').slick('slickNext');
    $('.masthead-image-slider').slick('slickNext');
});

// Settings for Resource and News Posts Sliders
var post_slider_settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    // fade: true,
    cssEase: 'ease',
    draggable: true,
    dots: false,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [{
            breakpoint: 9999,
            settings: 'unslick' // destroys slick
        },
        {
            breakpoint: 1056,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 1056,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 776,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 580,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

var two_column_post_slider_settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    cssEase: 'ease',
    draggable: true,
    dots: false,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [{
            breakpoint: 9999,
            settings: 'unslick' // destroys slick
        },
        {
            breakpoint: 1026,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 580,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

// Resource Post Slider
var $resourcePostSlider = $('.post_count_five .other_posts');

function initResourcePostSlider() {
    $resourcePostSlider.slick(post_slider_settings);
}

// Two column Resource Post Slider
var $twoColumnResourcePostSlider = $('.two-column.other_posts');

function initTwoColumnResourcePostSlider() {
    $twoColumnResourcePostSlider.slick(two_column_post_slider_settings);
}

// News Post Slider
var $newsPostSlider = $('.post_count_four .other_posts');

function initNewsPostSlider() {
    $newsPostSlider.slick(post_slider_settings);
}

function handlePostSlider(evt) {
    var window = $(this);
    //if window is less than 1057px then init slick slider
    if (window.width() < 1057) {
        if (!$resourcePostSlider.hasClass('slick-initialized')) {
            initResourcePostSlider();
        }

        if (!$newsPostSlider.hasClass('slick-initialized')) {
            initNewsPostSlider();
        }
        // return;
    }
    if (window.width() < 1026) {
        if (!$twoColumnResourcePostSlider.hasClass('slick-initialized')) {
            initTwoColumnResourcePostSlider();
        }
    }
    return;
}

var $resourcePostText = $('a.post-cta-btn').text();
var $newsPostText = $('a.news-post-cta-btn').text();

function updatePostCtaBtnText(evt) {
    var window = $(this);
    if (window.width() < 580) {
        $('a.post-cta-btn').text('More');
        $('a.news-post-cta-btn').text('More');
    } else if (window.width() > 580) {
        $('a.post-cta-btn').text($resourcePostText);
        $('a.news-post-cta-btn').text($newsPostText);
    }
}

if ($('.content-image-slider .image').length > 0) {
    $('.content-image-slider').slick({
        infinite: true,
        autoplay: false,
        autoplaySpeed: 5000,
        speed: 1000,
        fade: true,
        cssEase: 'ease',
        draggable: false,
        dots: true,
        arrows: false,
        appendDots: $('.slick-dots-slider')
    });
}
$('.prev').click(function() {
    $('.content-image-slider').slick('slickPrev');
});

$('.next').click(function() {
    $('.content-image-slider').slick('slickNext');
});

$('.facility-image-block').slickLightbox({
    itemSelector: '.facility-img img',
    closeOnEscape: true,
    closeOnBackdropClick: true,
    navigateByKeyboard: true,
    src: 'src'
});
$('.facility-image-block').slickLightbox({
    itemSelector: '.facility-img > a',
    closeOnEscape: true,
    closeOnBackdropClick: true,
    navigateByKeyboard: true
});

// Sidebar post sliders
// Settings for Sidebar Resource Post Sliders
var sidebar_post_slider_settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    // fade: true,
    cssEase: 'ease',
    draggable: true,
    dots: false,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [{
            breakpoint: 9999,
            settings: 'unslick' // destroys slick
        },
        {
            breakpoint: 836,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 825,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 560,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

// Sidebar Recent/Related Resource Post Slider
var $sidebarResourcePostSlider = $('.side-bar__posts');

function initSidebarResourcePostSlider() {
    $sidebarResourcePostSlider.slick(sidebar_post_slider_settings);
}

function handleSidebarPostSlider(evt) {
    var window = $(this);
    //if window is less than 1057px then init slick slider
    if (window.width() < 836) {
        if (!$sidebarResourcePostSlider.hasClass('slick-initialized')) {
            initSidebarResourcePostSlider();
        }
        return;
    }
}

// add shadow to header on scroll
$(window).scroll(function() {
    if ($(window).scrollTop() >= 30) {
        $('#header').addClass('shadow');
    } else {
        $('#header').removeClass('shadow');
    }
});

// Get height of header & masthead

var $window = $(window);

function check_if_in_view() {
    var $headerHeight = $('#header').height();
    var $mastheadHeight = $('.masthead-feature-container').height();
    var $offsetHeight = $headerHeight + $mastheadHeight;


    if ($window.scrollTop() >= $offsetHeight && $window.width() >= 1057) {
        $('#socialComponent').addClass('fixed');
    } else {
        $('#socialComponent').removeClass('fixed');
    }

    // for mobile fade in social component on scroll
    if ($window.scrollTop() > 300 && $window.width() <= 1056) {
        $('#socialComponent').addClass('active');
    } else {
        $('#socialComponent').removeClass('active');
    }
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');

$(window).resize(function() {
    mobileMenuIcon();
    handlePostSlider();
    updatePostCtaBtnText();
    handleSidebarPostSlider();
    buttonPartners();
});

$(document).ready(function() {
    mobileMenuIcon();
    handlePostSlider();
    updatePostCtaBtnText();
    handleSidebarPostSlider();
    buttonPartners();
    registerResourceSectionActive();
});

// replace the dnt=1 parameter that WordPress adds to the Vimeo player when using oEmbed
// jQuery(document).ready(function($) {
// find any Vimeo iframe
$('iframe[src*="player.vimeo.com"]').each(function() {
    // get the src
    var src = $(this).attr('src');
    // replace parameters with nothing
    $(this).attr('src', src.replace('dnt=1&', ''));
});
// });